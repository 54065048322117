@charset "UTF-8";
.no-selection {
  -webkit-user-select: none;
  user-select: none; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

/* Clearfix */
.clear:before, .clear:after {
  content: "";
  display: table; }

.clear:after {
  clear: both; }

.clear {
  zoom: 1; }

/* Floats */
.left {
  float: left; }

.right {
  float: right; }

/* Display */
.block {
  display: block; }

.inline {
  display: inline; }

.inline-block, audio, canvas, video, .inline-list, .inline-list li {
  display: inline-block;
  *zoom: 1;
  *display: inline; }

.none, audio:not([controls]),
[hidden],
.no-desktop {
  display: none; }

/* Font Weights */
.heavy {
  font-weight: 900; }

.strong, b, strong {
  font-weight: 700; }

.normal, cite, dt, table thead th,
table tfoot th, table caption, legend {
  font-weight: normal; }

.thin {
  font-weight: 300; }

.italic, i, em, .em, dfn, blockquote, q {
  font-style: italic; }

/* Font Styles */
.capitalize {
  text-transform: capitalize; }

.uppercase {
  text-transform: uppercase; }

/* Text Position */
.left-text, table caption {
  text-align: left; }

.right-text {
  text-align: right; }

.center-text {
  text-align: center; }

/* Accessibility (offscreen text for screen readers) */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  left: -9999em; }

.visuallyvisible {
  clip: auto;
  width: auto;
  height: auto;
  overflow: visible;
  left: 0; }

/* Margin and Padding Reset */
.no-margin {
  margin: 0; }

.no-padding {
  padding: 0; }

.no-overflow {
  overflow: hidden; }

.has-overflow {
  overflow: auto; }

.vis-overflow {
  overflow: visible !important; }

.cursor-default {
  cursor: default; }

.cursor-pointer {
  cursor: pointer; }

/* Resets */
* {
  box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

html, button, input, select, textarea {
  font-family: sans-serif; }

body, form, fieldset, legend, input, select, textarea, button {
  margin: 0; }

html {
  font-size: 100%; }

/* Resets */
* {
  box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

html, button, input, select, textarea {
  font-family: sans-serif; }

body, form, fieldset, legend, input, select, textarea, button {
  margin: 0; }

html {
  font-size: 100%; }

/* ==========================================================================
/* 1.0 - Document Setup (typography, links, paragraph, etc)
   ========================================================================== */
/* Body */
body {
  font-family: sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem;
  color: #434343;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

/* Strong and Italic */
/* Links */
a {
  color: #443AB7;
  text-decoration: none; }

a:hover {
  text-decoration: none; }

a:active, a:hover {
  outline: 0;
  text-decoration: none; }

/* Paragraph and Small */
p {
  margin: 0 0 1.6em 0; }

pre {
  margin: 1em 0; }

ul + p, ul + pre, ol + p, ol + pre {
  margin-top: 0; }

small {
  font-size: 80%; }

/* Abbreviations */
abbr[title] {
  border-bottom: 1px dotted; }

/* Audio */
audio:not([controls]) {
  height: 0; }

/* Small,
Sub and Sup */
small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

/* Definition */
/* Images */
img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

.image-left {
  margin-right: 20px; }

.image-right {
  margin-left: 20px; }

/* 1.1 Headings - h1, h2, h3, h4, h5, h6 & other (if any)
   ========================================================================== */
h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  margin: 0 0 .33em 0;
  -webkit-font-smoothing: antialiased; }

h1, .h1 {
  font-size: 40px;
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 32px;
  font-size: 2rem; }

h3, .h3 {
  font-size: 26px;
  font-size: 1.625rem; }

h4, .h4 {
  font-size: 20px;
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 18px;
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 16px;
  font-size: 1rem; }

/* Reset Heading */
.no-style-heading {
  margin: 0;
  font-size: 100%; }

/* Horizontal Rule */
hr {
  border: 0;
  background: none;
  outline: 0;
  box-sizing: content-box;
  height: 1px; }

/* 1.2 Quotes and Code - block quotes, quotes, code, pre, etc
   ========================================================================== */
/* Block Quotes and Quotes */
blockquote {
  color: #434343;
  margin: 1em 0; }
  blockquote p {
    margin: 1em 0; }

cite {
  color: #434343;
  font-style: normal;
  font-size: 16px;
  font-size: 1rem;
  line-height: normal; }

q {
  quotes: none; }

q:before, q:after {
  content: '';
  content: none; }

/* Mark */
mark {
  background: #FFF8B4;
  color: #111; }

/* Code */
code, kbd, pre, samp {
  word-wrap: break-word;
  font-family: 'courier new', monospace, serif;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: normal;
  background-color: #f1f1f1;
  padding: 10px; }

code {
  color: #111; }

/* 1.3 Lists
   ========================================================================== */
dl, menu, ol, ul {
  margin: 1em 0; }

dd,
ul ul,
ol ol,
ul ol,
ol ul {
  margin: 0; }

dd {
  margin-bottom: 1em; }

menu, ol, ul {
  padding: 0; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none; }

ul li {
  list-style: none; }

/* Remove Styling from List */
.unstyled-list, .inline-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .unstyled-list li, .inline-list li {
    margin-top: 0;
    margin-bottom: 0; }

/* 1.4 Tables
   ========================================================================== */
table {
  width: 100%;
  margin: 1em 0;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  word-wrap: break-word;
  -ms-word-wrap: break-word;
  *white-space: normal; }

table th,
table td {
  padding: 8px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee; }

table thead th,
table tfoot th {
  vertical-align: bottom;
  background-color: #f1f1f1;
  color: #333; }

table caption {
  padding: 8px;
  font-weight: normal;
  font-style: normal;
  border-bottom: 0; }

/* 1.5 Forms, Labels and Inputs
   ========================================================================== */
fieldset {
  border: 0;
  padding: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

label {
  margin: 0;
  cursor: pointer; }

button, input, select, textarea {
  font-size: 100%;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

/* Buttons */
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

@media print {
  * {
    background: transparent; }
  a, a:visited {
    text-decoration: underline; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100%; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

.dropdown {
  z-index: -1;
  position: absolute;
  background-color: #FFF;
  visibility: hidden;
  display: none;
  backface-visibility: hidden;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 3px; }
  .dropdown.open {
    display: block;
    visibility: visible;
    z-index: 9; }

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2; }

.popup.dropdown.open {
  animation-duration: 0.6s;
  animation-name: popupMenu; }

/* Generated with Bounce.js. Edit at https://goo.gl/tR6n8A */

@keyframes popupMenu {
  0% {
    transform: perspective(1px) matrix3d(0.95, 0, 0, 0, 0, 0.95, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  3.4% {
    transform: perspective(1px) matrix3d(0.966, 0, 0, 0, 0, 0.97, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  4.7% {
    transform: perspective(1px) matrix3d(0.972, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.81% {
    transform: perspective(1px) matrix3d(0.983, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  9.41% {
    transform: perspective(1px) matrix3d(0.994, 0, 0, 0, 0, 1.008, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  10.21% {
    transform: perspective(1px) matrix3d(0.997, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  13.61% {
    transform: perspective(1px) matrix3d(1.006, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  14.11% {
    transform: perspective(1px) matrix3d(1.007, 0, 0, 0, 0, 1.017, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.52% {
    transform: perspective(1px) matrix3d(1.01, 0, 0, 0, 0, 1.012, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.72% {
    transform: perspective(1px) matrix3d(1.011, 0, 0, 0, 0, 1.009, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  21.32% {
    transform: perspective(1px) matrix3d(1.01, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  24.32% {
    transform: perspective(1px) matrix3d(1.008, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  25.23% {
    transform: perspective(1px) matrix3d(1.007, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.03% {
    transform: perspective(1px) matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  29.93% {
    transform: perspective(1px) matrix3d(1.002, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  35.54% {
    transform: perspective(1px) matrix3d(0.999, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  36.74% {
    transform: perspective(1px) matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  41.04% {
    transform: perspective(1px) matrix3d(0.998, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.44% {
    transform: perspective(1px) matrix3d(0.998, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  52.15% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  59.86% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  63.26% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  75.28% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  85.49% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  90.69% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: perspective(1px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }

@keyframes slideDown {
  0% {
    transform: translateY(-10%); }
  100% {
    transform: translateY(0%); } }

.slide-down.dropdown.open {
  animation-duration: 150ms;
  animation-name: slideDown; }

@keyframes fade-in-out {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in-out.dropdown.open {
  animation-duration: 300ms;
  animation-name: fade-in-out; }

*:focus {
  outline: none; }

html,
body {
  margin: 0;
  padding: 0; }

body {
  font-family: "Open Sans", "Lato", "Helvetica Neue", Helvetica, Arial, "PingFang TC", "PingFang SC", "STHeiti", "微軟正黑體", "Microsoft Yahei", sans-serif;
  overflow-x: auto;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }
  body h1, body h2, body h3, body h4, body h5, body h6, body .headline {
    font-weight: 600;
    font-family: "Open Sans", "Lato", "Helvetica Neue", Helvetica, Arial, "PingFang TC", "PingFang SC", "STHeiti", "微軟正黑體", "Microsoft Yahei", sans-serif; }
    body h1 > *, body h2 > *, body h3 > *, body h4 > *, body h5 > *, body h6 > *, body .headline > * {
      font-family: "Open Sans", "Lato", "Helvetica Neue", Helvetica, Arial, "PingFang TC", "PingFang SC", "STHeiti", "微軟正黑體", "Microsoft Yahei", sans-serif; }

input, textarea {
  -webkit-appearance: none;
  appearance: none;
  font-family: "Open Sans", "Lato", "Helvetica Neue", Helvetica, Arial, "PingFang TC", "PingFang SC", "STHeiti", "微軟正黑體", "Microsoft Yahei", sans-serif;
  border-radius: 3px;
  transition: box-shadow border-color 333ms cubic-bezier(0.26, 0.094, 0.795, 1.04);
  /* custom */
  transition-timing-function: cubic-bezier(0.26, 0.094, 0.795, 1.04);
  /* custom */ }
  input:focus, textarea:focus {
    outline: none;
    box-shadow: none; }
  input::-webkit-contacts-auto-fill-button, textarea::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0; }
  input::-webkit-credentials-auto-fill-button, textarea::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0; }

textarea {
  vertical-align: middle;
  border-color: #ccc;
  font-size: 16px;
  padding-left: 10px;
  color: #606972;
  border: 1px solid #9B9B9B;
  resize: none;
  box-shadow: 0 0 0 1px transparent;
  box-sizing: border-box; }

.dialog-flex {
  display: flex; }

body {
  	margin: 0;
  	padding: 0;
	font-family: sans-serif;
	word-break: break-all;
}

img {
	max-width: 100%;
}
